import React, { Fragment, useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Particle from "../components/Particle";
import TypeWriter from "../components/Typewriter";
import HomeIntro from "../components/HomeIntro";
import Helmet from 'react-helmet'
import { useLocation } from "react-router-dom";
export default function Home() {
  const location = useLocation();
  const [currentUrl, setCurrentUrl] = useState('');
  useEffect(() => {
    let pageUrl = `${window.location.origin}${location.pathname}`;
    setCurrentUrl(pageUrl);
  }, [location]);
  return (
    <Fragment>
      <Helmet>
        <link rel="canonical" href={currentUrl} />
        <title>Karim Halani's Portfolio: Creativity and Technology</title>
        <meta name="description"
          content="Embark on a journey through my React.js-powered website. Explore projects, achievements, and passions in a seamless blend of creativity & technology." />
      </Helmet>
      <Container fluid className="home-section" id="home">
        <Particle />
        <Container className="home-content">
          <Row>
            <Col md={7} className="home-header">
              <Row>
                <h1 style={{ paddingBottom: 15 }} className="heading">
                  Hi There!{" "}
                  <span className="wave" role="img" aria-labelledby="wave">
                    👋🏻
                  </span>
                </h1>
              </Row>
              <Row>
                <h1 className="heading-name">
                  I am
                  <strong className="main-name"> Karim Aziz Halani</strong>
                </h1>
              </Row>
              <div className="typewriter-div">
                <TypeWriter />
              </div>
              {/* <div className="navigation-button-container">
                <NavigateButtons />
              </div> */}
            </Col>
            <Col md={5}>
              <img
                src={'/assets/images/home-main.svg'}
                alt="home pic"
                className="img-fluid"
                style={{ maxHeight: "600px" }}
              />
            </Col>
          </Row>
        </Container>
      </Container >
      <HomeIntro />
    </Fragment >
  );
}