import React from "react";
import Card from "react-bootstrap/Card";

export default function AboutCard() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p>
            Hi Everyone, I am <span className="purple">Karim Aziz Halani </span>
            founder of <span className="purple"> PremierOne Tech.</span>
            <br />
            With a passion for tech innovation, I founded this company to craft bespoke software solutions.
            <br /><br />
            Over 8 years, I've honed a deep understanding of the industry.
            <br />
            At PremierOne Tech, we're not just about code; we're about solving your unique business challenges. I'm excited about the prospect of collaborating with you to transform your business through technology.
          </p>
          <p style={{ color: "rgb(155 126 172)" }}>
            <br /><br /><br /><br />
            "Architecting tomorrow's innovations with the precision of today's technology!"
          </p>
          <footer className="blockquote-footer">hakr14</footer>
        </blockquote>
      </Card.Body>
    </Card>
  );
}