import React, { Fragment, useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Particle from "../components/Particle";
import AboutCard from "../components/AboutCard";
import Github from "../components/Github";
import StackList from "../components/StackList";
import { Constants } from "../Constants";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";


export default function About() {
  const location = useLocation();
  const [currentUrl, setCurrentUrl] = useState('');
  useEffect(() => {
    let pageUrl = `${window.location.origin}${location.pathname}`;
    setCurrentUrl(pageUrl);
  }, [location]);
  useEffect(() => {
    if (window.location.href.includes('#skills-section')) {
      let skillsSection: any = document.getElementById('skills-section');
      window.scrollTo(0, skillsSection.offsetTop);
    }
  })
  return (
    <Container fluid className="about-section">
      <Helmet>
        <link rel="canonical" href={currentUrl} />
        <title>Karim Halani's Story: Behind the Scenes of following my passion</title>
        <meta name="description"
          content="Go beyond my work as a developer. Learn about my values, passions, and experiences that shape my approach. Discover what drives me and how I innovate." />
      </Helmet>
      <Particle />
      <Container>
        <Row style={{ justifyContent: "center", padding: "10px" }}>
          <Col
            md={7}
            style={{
              justifyContent: "center",
              paddingTop: "30px",
              paddingBottom: "50px",
            }}
          >
            <h1>
              Know Who <strong className="purple">I'M</strong>
            </h1>
            <AboutCard />
          </Col>
          <Col
            md={5}
            style={{ paddingTop: "120px", paddingBottom: "50px" }}
            className="about-img"
          >
            <img src={'/assets/images/about.png'} alt="about" className="img-fluid" />
          </Col>
        </Row>
        <h1 id='skills-section' className="project-heading">
          Professional <strong className="purple">Skillset </strong>
        </h1>
        {Constants.TECH_STACK.map((stackList, index) => {
          return <Fragment key={index}>
            <h2>{stackList.title}</h2>
            <StackList stack={stackList.list} type={stackList.type} />
          </Fragment>
        })}
        <h1 className="project-heading">
          <strong className="purple">Tools</strong> I use
        </h1>
        <StackList stack={Constants.TOOL_STACK} type="tools" />
        <Github />
      </Container >
    </Container >
  );
}