import { Constants } from "../Constants"
import { ISocialIcon } from "../Interfaces";

export default function SocialIcons(props: ISocialIcon) {
    return (Constants.SOCIAL_STACK.map((social) => {
        return <li className="social-icons" key={social.icon.toString()}>
            <a
                href={social.url}
                style={{ color: "white" }}
                target="_blank"
                rel="noopener noreferrer"
            >
                {social.url === 'https://www.premieronetech.com' ?
                    <div style={{ width: props.size, height: props.size }}>
                        <img src={'/assets/images/premieronetech.png'} alt={"POT"} style={{ width: '100%', height: '100%', borderRadius: '50%' }} />
                    </div>
                    : <social.icon size={props.size} />}
            </a>
        </li>
    }));
}