import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Tilt from "react-parallax-tilt";
import SocialIcons from "./SocialIcons";
import { Constants } from "../Constants";
import NavigateButtons from "./NavigateButtons";

export default function HomeIntro() {
  return (
    <Container fluid className="home-about-section" id="about">
      <Container>
        <Row>
          <Col md={8} className="home-about-description">
            <h1>
              LET ME <span className="purple"> INTRODUCE </span> MYSELF
            </h1>
            <p className="home-about-body">
              Programming is my first love and I am <strong className="purple">passionate</strong> about designing creative solutions to complex problems.
              <br />
              <br />
              I am fluent in classics like
              <i>
                <b className="purple"> Javascript, Java, C# and Dart.</b>
              </i>
              <br />
              <br />
              My fields of interest are building new&nbsp;
              <i>
                <b className="purple">Web Technologies and Products </b> and
                also in areas related to{" "}
                <b className="purple">
                  Blockchain.
                </b>
              </i>
              <br />
              <br />
              My scope of work also allows me to develop products
              with <b className="purple">Node.js</b> and
              <i>
                <b className="purple">
                  {" "}
                  Modern Javascript Library and Frameworks
                </b>
              </i>
              &nbsp; like
              <i>
                <b className="purple"> React.js and Next.js</b>
              </i>
            </p>
          </Col>
          <Col md={4} className="myAvtar">
            <Tilt>
              <div className="tilt-image" id="kah">
                {/* eslint-disable-next-line */}
                <a className="img-fluid" />
              </div>
            </Tilt>
          </Col>
        </Row>
        <Row>
          <p className="home-about-body"><strong className="purple">Skills</strong> I Know</p>
          {Constants.HOME_SKILL_STACK.map((stackList, index) => {
            return <div key={index} className="home-tech-icons">
              <stackList.icon size={40} color={"#ce5ff8"} />
            </div>
          })}
        </Row>
        <NavigateButtons />
        <br /><br /><br /><br />
        <Row>
          <Col md={12} className="home-about-social">
            <h1> Feel free to <span className="purple">connect </span>with me</h1>
            <ul className="home-about-social-links">
              <SocialIcons size={20} />
            </ul>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}
