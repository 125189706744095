import React, { Fragment, useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "../components/ProjectCards";
import Particle from "../components/Particle";
import { Constants } from "../Constants";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";


export default function Projects() {
  const location = useLocation();
  const [currentUrl, setCurrentUrl] = useState('');
  useEffect(() => {
    let pageUrl = `${window.location.origin}${location.pathname}`;
    setCurrentUrl(pageUrl);
  }, [location]);

  const navigateToProject = (projectUrl: string) => {
    console.log("window.location.origin");
    console.log(window.location.origin);
    window.location.href = `${window.location.origin}/projects/${projectUrl}`;
  };
  return (
    <Fragment>
      <Helmet>
        <link rel="canonical" href={currentUrl} />
        <title>Karim Halani's Projects: Bringing Ideas to Life</title>
        <meta name="description"
          content="Discover my projects showcasing skills & creativity. From Open Source ERPs to custom dev, explore how I bring ideas to life." />
      </Helmet>
      <Container fluid className="project-section">
        <Particle />
        <Container className="project-container">
          <h1 className="project-heading">
            Portfolio <strong className="purple">Highlights</strong>
          </h1>
          <p>
            <strong className="purple">Featured</strong> Endeavors
          </p>
          <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
            {Constants.PROJECT_DETAILS.map((project) => {
              return <Col md={4} className="project-card" key={project.title} onClick={() => navigateToProject(project.detailUrl)}><ProjectCard
                imgPath={project.imgPath}
                title={project.title}
                description={project.description}
                ghLink={project.ghLink}
                demoLink={project.demoLink}
                details={project.details ? project.details : undefined}
                detailUrl={project.detailUrl}
              />
              </Col>
            })}
          </Row>
        </Container>
      </Container>
    </Fragment>

  );
}
