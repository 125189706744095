import React, { Fragment, useEffect, useState } from "react";
import { Container, Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Particle from "../components/Particle";
import { AiOutlineDownload, AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";
import { Document, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

export default function Resume() {
  const [numPages, setNumPages] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [width, setWidth] = useState(1200);
  const [currentUrl, setCurrentUrl] = useState('');
  const location = useLocation();

  useEffect(() => {
    let pageUrl = `${window.location.origin}${location.pathname}`;
    setCurrentUrl(pageUrl);
  }, [location]);

  useEffect(() => {
    setWidth(window.innerWidth);
    setNumPages(2)
  }, []);

  function onDocumentLoadSuccess({ numPages }: { numPages: number }): void {
    setNumPages(numPages);
  }

  function updatePageNumber() {
    setPageNumber(numPages > pageNumber ? pageNumber + 1 : pageNumber - 1)
  }

  return (
    <Fragment>
      <Helmet>
        <link rel="canonical" href={currentUrl} />
        <title>Karim's Resume: A Summary of My Professional Background and Expertise</title>
        <meta name="description"
          content="Download my resume and discover my skills, experience & achievements as a developer." />
      </Helmet>
      <Container fluid className="resume-section">
        <Particle />
        <Row style={{ justifyContent: "center", position: "relative" }}>
          <Button
            variant="primary"
            href={'/assets/kah.pdf'}
            target="_blank"
            style={{ maxWidth: "250px" }}
          >
            <AiOutlineDownload />
            &nbsp;Download CV
          </Button>
        </Row>
        <Row className="resume">
          <Row style={{ justifyContent: "center", position: "relative" }}>
            <div style={{ display: "flex", justifyContent: "center", alignItems: 'baseline' }}>
              <Button variant="primary" style={{ maxWidth: "50px" }}>
                <AiOutlineArrowLeft style={{ cursor: 'pointer' }} size={20} onClick={() => { updatePageNumber() }} />
              </Button>
              <p style={{ padding: "0 5%", fontSize: "20px" }} >{pageNumber} / {numPages}</p>
              <Button variant="primary" style={{ maxWidth: "50px" }}>
                <AiOutlineArrowRight style={{ cursor: 'pointer' }} size={20} onClick={() => { updatePageNumber() }} />
              </Button>
            </div>
          </Row>
          <Document
            file='/assets/kah.pdf'
            onLoadSuccess={onDocumentLoadSuccess}
            className="d-flex justify-content-center"
          >
            <Page scale={width > 786 ? 1.7 : 0.6} pageNumber={pageNumber} renderMode={'canvas'} />
          </Document>

        </Row>
      </Container>
    </Fragment>
  );
}