import React from "react";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import { CgWebsite } from "react-icons/cg";
import { BsGithub } from "react-icons/bs";
import { IProjectCard } from "../Interfaces";
import { Link } from "react-router-dom";

export default function ProjectCards(props: IProjectCard) {
  const handleButtonClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
  };

  return (
    <Card className="project-card-view">
      <Card.Img variant="top" src={props.imgPath} alt="card-img" />
      <Card.Body>
        <Card.Title>{props.title}</Card.Title>
        <Card.Text style={{ textAlign: "center" }}>{props.description}</Card.Text>
        {props.ghLink && (
          <Button
            variant="primary"
            href={props.ghLink}
            target="_blank"
            onClick={handleButtonClick}
          >
            <BsGithub /> &nbsp;{"GitHub"}
          </Button>
        )}
        {props.demoLink && (
          <Button
            variant="primary"
            href={props.demoLink}
            target={props.demoLink.includes("premieronetech") ? "_self" : "_blank"}
            style={{ marginLeft: "10px" }}
            onClick={handleButtonClick}
          >
            <CgWebsite /> &nbsp;{props.title.includes("meet") ? "Website" : "Demo"}
          </Button>
        )}
        <Link to={`/projects/${props.detailUrl}`} style={{ textDecoration: 'none' }}>
          <Button
            variant="secondary"
            style={{ marginLeft: "10px" }}
          >
            See More
          </Button>
        </Link>
      </Card.Body>
    </Card>
  );
}
