import React, { Fragment, useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { Constants } from '../Constants';
import _ from 'lodash'
import { Helmet } from 'react-helmet';

export default function ProjectDetailsPage() {
    const { detailUrl } = useParams();
    const location = useLocation();
    const [currentUrl, setCurrentUrl] = useState('');
    useEffect(() => {
        let pageUrl = `${window.location.origin}${location.pathname}`;
        setCurrentUrl(pageUrl);
    }, [location]);
    const project = Constants.PROJECT_DETAILS.find(p => p.detailUrl === detailUrl);

    if (!project) {
        return <div>Project not found.</div>;
    }

    const splitTextAtUppercase = (text: string) => _.startCase(text.split(/(?=[A-Z])/).join(" "));

    function intToRoman(num: number) {
        const romanNumerals = ["I", "IV", "V", "IX", "X", "XL", "L", "XC", "C", "CD", "D", "CM", "M"];
        const values = [1, 4, 5, 9, 10, 40, 50, 90, 100, 400, 500, 900, 1000];

        let result = '';
        for (let i = values.length - 1; i >= 0; i--) {
            while (num >= values[i]) {
                result += romanNumerals[i];
                num -= values[i];
            }
        }

        return result;
    }

    return (
        <div className="project-details-container" >
            <Helmet>
                <link rel="canonical" href={currentUrl} />
                <title>{project.title}</title>
                <meta name="description"
                    content={project.description} />
            </Helmet>
            {project.details && (
                <Fragment>
                    <h1 className="project-details-header">{project.title}</h1>
                    <div className='project-details-split-container'>
                        <div className='project-details-split-image'>
                            <img src={project.imgPath} alt="" />
                        </div>
                        <div className="project-details-section project-details-split-body" >
                            {Object.entries(project.details).map(([key, value], index) => {
                                return <Fragment key={key}>
                                    <h2 className="project-details-title">{intToRoman(index + 1)}. {splitTextAtUppercase(key)}</h2>
                                    <div className="project-details-text">
                                        {value && value.toString().length > 1 ? (
                                            <ul className="project-details-list">
                                                {value.toString().split('\n').map((line: string, idx: number) => (
                                                    <li key={idx} className="project-details-list-item">{line}</li>
                                                ))}
                                            </ul>
                                        ) : (
                                            value
                                        )}
                                    </div>
                                </Fragment>
                            })}
                        </div>
                    </div>
                </Fragment>
            )
            }
        </div >
    );
}
