import React from "react";
import { IStackList } from "../Interfaces";

export default function StackList(props: IStackList) {
  return (
    <div className="stack-grid">
      {props.stack.map((item, index) => {
        return props.type === item.type && <div key={index} className="tech-icons">
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <item.icon size={60} color={"#ce5ff8"} />
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-around' }}>
            <p style={{ paddingTop: '1em' }}>{item.name}</p>
          </div>
        </div>
      })}
    </div>
  );
}
