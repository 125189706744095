import React, { Fragment } from 'react'
import { Button, Col, Row } from 'react-bootstrap'

export default function NavigateButtons() {
    let buttonDetails = [{ name: 'More About Me', href: '/about' }, { name: 'Resume', href: '/resume' }, { name: 'Projects', href: '/projects' }, { name: 'Skills', href: '/about#skills-section' }]

    return (
        <Fragment>
            <Row>
                <Col md={12}className="navigate-buttons">
                    {buttonDetails.map((item, index) => {
                        return <Button key={index} className="section-button" variant="primary" href={item.href} target="_self" >{item.name}</Button>
                    })}
                </Col>
            </Row>
        </Fragment>
    )
}
