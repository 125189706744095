import React from "react";
import Typewriter from "typewriter-effect";

export default function TypeWriter() {
  return (
    <Typewriter
      options={{
        strings: [
          "Founder of PremierOne Tech",
          "Solutions Architect",
          "Cloud Solutions Engineer",
          "DevOps Engineer",
          "IT Consultant",
          "Full Stack Developer",
          "Open Source Contributor",
        ],
        autoStart: true,
        loop: true,
        deleteSpeed: 20,
      }}
    />
  );
}
